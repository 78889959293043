import { Client } from '@stomp/stompjs'
import {EventEmitter} from "../AppState";

const webSocketProtocol = window.location.protocol == 'https:' ? 'wss' : 'ws';
const stompClient = new Client({
    brokerURL: webSocketProtocol + '://' + window.location.host + '/module/chat/web-socket'
});

stompClient.onWebSocketError = (error) => {
    console.error('Error with websocket', error);
};

stompClient.onStompError = (frame) => {
    console.error('Broker reported error: ' + frame.headers['message']);
    console.error('Additional details: ' + frame.body);
};


export function connect(topic, username) {
    stompClient.activate();
    stompClient.onConnect = (frame) => {
        console.log('Connected: ' + frame);
        stompClient.subscribe('/'+ topic + '/' + username, (message) => {
            EventEmitter.dispatchEvent('NEW_MESSAGE')
            EventEmitter.dispatchEvent('NEW_MESSAGE_CHATBOX')
        });
    };
}

export function disconnect() {
    stompClient.deactivate();
    console.log("Disconnected");
}

export function sendName() {
    stompClient.publish({
        destination: "/topic/common",
        body: JSON.stringify({'name': 'test'})
    });
}


