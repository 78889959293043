import styles from '../Form.module.css';
import React from "react";
import img1 from "./img1.png";
import img2 from "./img2.png";
import KontaktForm from "../../kontakt/Form";
import {Bounce, Slide, Zoom} from "react-awesome-reveal";
function Form(props) {
    return (
        <div>
            <div className={styles.top}>
                <div className="wrapper">


                    <div className="grid">
                        <div className="col-4">
                            <div style={{textAlign: 'center', width: '100%'}}>
                                <img src={img1} style={{width: '100%'}}/>
                            </div>
                        </div>
                        <div className="col-8">
                            <div>
                                <span className={styles.label}>Leadgenerierung</span>
                                <br/>
                                Mehr Kunden gewinnen mit einem Erklärvideo!
                                <br/>
                                <br/>
                                Ein professionell erstelltes Erklärvideo ist der Schlüssel, um mehr Leads zu generieren. Das bedeutet, dass sich mehr interessierte Menschen in potenzielle Kundschaft verwandeln! Die kurze Aufmerksamkeitsspanne im Internet ist keine Herausforderung mehr, denn unser hochwertiges Erklärvideo fesselt und überzeugt Ihr Publikum.
                                <br/><br/>
                                Das Geheimnis unseres Erfolgs liegt in der individuellen Ausrichtung auf Ihre spezifische Zielgruppe. Mit einem maßgeschneiderten Konzept sprechen wir Ihre potenziellen Kunden direkt an und wecken ihr Interesse für Ihr Angebot. Im Vergleich zu langen Texten werden spannende Erklärvideos viel eher wahrgenommen und angeschaut.
                                Mit einem spannenden Erklärvideo gewinnen Sie das Interesse Ihrer Zielgruppe im Handumdrehen, ohne dass sie sich durch lange Texte arbeiten müssen.

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="wrapper">

                    <center>
                        <div className={"venmediaHeader3 " + styles.itemHeader}>Warum ein Erklärvideo so erfolgreich ist?</div>
                        <div className={styles.itemContent}>
                            Visuelle Faszination: Bewegte Bilder und ansprechende Animationen ziehen die Aufmerksamkeit Ihrer Zielgruppe sofort auf sich und machen komplexe Inhalte leicht verständlich.
                        </div>
                    </center>
                    <div className={styles.items}>
                        <div className="grid">
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <Bounce delay="50" triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '140px', maxWidth: '100%'}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={"venmediaHeader3 " + styles.itemHeader}>Emotionale Bindung<br/></div>
                                            <div className={styles.itemContent}>
                                                Ein gut gemachtes Erklärvideo kann Emotionen wecken und eine starke Verbindung zu Ihrem Unternehmen und Ihrer Marke herstellen.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <Bounce delay="100" triggerOnce>
                                    <div className="p-grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '160px', maxWidth: '100%'}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={"venmediaHeader3 " + styles.itemHeader}>Zeitersparnis</div>
                                            <div className={styles.itemContent}>
                                                In kurzer Zeit vermitteln Sie Ihre Botschaft auf prägnante und unterhaltsame Weise, ohne dass Ihre potenziellen Kunden lange Texte lesen müssen.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <Bounce delay="150" triggerOnce>
                                    <div className="p-grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '160px', maxWidth: '100%'}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={"venmediaHeader3 " + styles.itemHeader}>Steigerung der Verweildauer</div>
                                            <div className={styles.itemContent}>
                                                Ein ansprechendes Video hält die Zuschauer länger auf Ihrer Website und steigert so die Chancen, dass sie zu Kunden werden.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">

                <div  style={{textAlign: 'center'}}>

                    <div className="venmediaHeader">Mit Einem Erklärvideo Zu Mehr Kunden!</div>
                    <div >
                        Teilen und Verbreiten: Wenn Ihr Erklärvideo Ihre Zielgruppe begeistert, werden sie es gerne teilen, was zu einer organischen Verbreitung führt.
                        <br/><br/>
                        Zögern Sie nicht länger und setzen Sie auf die Kraft der Animation. Mit Venmedia als Partner stehen Ihnen kreative und erfahrene Köpfe zur Verfügung, die Ihnen dabei helfen, Ihr Produkt oder Ihre Dienstleistung auf eine frische und einprägsame Weise zu präsentieren. Lassen Sie uns gemeinsam Ihre Erfolgsgeschichte schreiben!
                        <br/><br/>
                        Kontaktieren Sie uns noch heute, um mehr darüber zu erfahren, wie wir Sie mit unseren animierten Erklärvideos auf dem Weg zur erfolgreichen Leadgenerierung unterstützen können. Wir freuen uns darauf, von Ihnen zu hören!
                    </div>

                </div>
            </div>

            <KontaktForm/>
        </div>
    )
}
export default Form