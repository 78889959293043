import React, {useEffect, useState} from 'react';
import styles from './ChatBox.module.css';
import ChatForm from "../application-react-lib/chat/Form";
import {connect, disconnect} from "../application-react-lib/chat/ChatWebSocket";
import imgChat from "./img/message.png"
import imgAvatar from "./img/avatar.png"



function ChatBox(props) {


    const [selectedChat, setSelectedChat] = useState();
    const [username, setUsername] = useState();


    useEffect(() => {

        if(localStorage.getItem("username") == null) {
            localStorage.setItem('username', services.randomUsername())
        }
        setUsername(localStorage.getItem("username"))

        disconnect()
        connect('chats', localStorage.getItem("username"));
    }, []);



    const services = {
        randomUsername: () => {
            var one = Math.floor(1000 + Math.random()*(9999 - 1000 + 1))
            var two = Math.floor(1000000 + Math.random()*(9999999 - 1000000 + 1))
            return one+'.'+two+'@venmedia.de'
        },
    }
    return <>
        <div className={styles.root}
             onClick={() => setSelectedChat({
                 recipient: "a.ince@venmedia.de",
                 recipientInitials: "JW",
                 recipientName: "Julia W."
             })}>
            <table>
                <tbody>
                <tr>
                    <td>
                        <img src={imgChat} style={{width: 40, paddingTop: 10, margin: '0px 5px 0px 15px'}}/>
                    </td>
                    <td>
                        <div style={{fontSize: 22, fontWeight: "bold", paddingTop: 7, lineHeight: 1.0}}>Chatten Sie mit
                            uns!
                        </div>

                        <table cellSpacing="0" cellPadding="0">
                            <tbody>
                            <tr>
                                <td style={{whiteSpace: 'nowrap'}}>
                                    <div style={{fontSize: 12, fontWeight: "bold"}}>1 Berater</div>
                                    <div className={styles.chatHeaderOnline}>
                                        <i className="fa-solid fa-circle"></i> Online
                                    </div>
                                </td>
                                <td style={{width: '100%', textAlign: 'center'}}>
                                    <div className={styles.buttonStarten}>starten</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <ChatForm
            username={username}
            recipient={selectedChat?.recipient}
            onHide={() => setSelectedChat(null)}
            recipientInitals={selectedChat?.recipientInitials}
            recipientName={selectedChat?.recipientName}
            avatar={imgAvatar}
        />
    </>;
}

export default ChatBox;