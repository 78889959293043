import styles from './Form.module.css';
import React from "react";
import {Bounce, Fade, Slide} from "react-awesome-reveal";
import icon1 from "./icon1.png";
import icon2 from "./icon2.png";
import icon3 from "./icon3.png";

function Form(props) {


    const VisibilityTracker = (visibilityProps) => {
        return (
            <div className={ visibilityProps.isVisible ? styles.item : styles.itemTransition}>
                {visibilityProps.children}
            </div>);
    }


    return (
        <div style={{background: 'rgba(232,232,232,0)'}}>
            <div style={{position: 'absolute'}}>
                <div style={{borderRadius: 150, marginTop: '30vw', marginLeft: '-15vw', height: '20vw', width: '20vw', background: '#51516E', boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}/>
            </div>
            <div className="wrapper" ref={props.childRef} style={{paddingTop: 0}}>

                <center>
                    <div className="venmediaHeader2" style={{paddingTop: 40}}>Unsere Vorteile</div>
                </center>


                <div className={styles.items}>
                    <div className="grid">

                        <div className={"lg:col-4 col-12 " + styles.item}>
                            <br/>
                            <Bounce triggerOnce>
                                <div className="grid">
                                    <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={icon1} style={{width: '120px', maxWidth: '100%'}}/></div>
                                    <div className={"lg:col-12 col-8"}>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Zielgerechtes Erklärvideo</div>
                                        <div className={styles.itemContent}>
                                            Zuerst definieren wir die Zielgruppe und erarbeiten ein passgenaues Konzept. Schlüsselszenen kommen in dem Augenblick zum Vorschein, in dem die Aufmerksamkeitskurve steigt. Genau diese Besonderheiten machen ein Erklärvideo vertriebsgerecht.
                                        </div>
                                    </div>
                                </div>
                            </Bounce>
                        </div>
                        <div className={"lg:col-4 col-12 " + styles.item}>
                            <br/>
                            <Bounce delay="50" triggerOnce>
                                <div className="grid">
                                    <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={icon2} style={{width: '140px', maxWidth: '100%'}}/></div>
                                    <div className="lg:col-12 col-8">
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Full Service<br/></div>
                                        <div className={styles.itemContent}>
                                            Ihr eigener VENmedia Ansprechpartner steht mit Ihnen in ständigem Kontakt und informiert Sie über jeden Schritt. Unser Anspruch liegt in einem schnellen Service, bei dem immer Zeit für die Beantwortung Ihrer Fragen bleibt.
                                        </div>
                                    </div>
                                </div>
                            </Bounce>
                        </div>
                        <div className={"lg:col-4 col-12 " + styles.item}>
                            <br/>
                            <Bounce delay="100" triggerOnce>
                                <div className="grid">
                                    <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={icon3} style={{width: '160px', maxWidth: '100%'}}/></div>
                                    <div className="lg:col-12 col-8">
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Unbegrenzte Korrekturen</div>
                                        <div className={styles.itemContent}>
                                            Wir arbeiten dafür, dass unsere Kund*innen zu 100 % mit den Ergebnissen zufrieden sind. Daher beziehen wir alle Ihre Wünsche mit ein. Korrekturen sind kostenfrei – und das in unbegrenzter Anzahl.
                                        </div>
                                    </div>
                                </div>
                            </Bounce>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Form