import styles from "./Form.module.css";
import image from "./instapaket.png"
import welleImg from "../area6/welleTop.svg";
import React from "react";

function Form(props) {


    return (
        <div>

            <div className="wrapper">
                <div className={styles.item}>
                    <div className="grid">
                        <div className="col-3">
                            <img src={image} style={{width: '100%'}}/>
                        </div>
                        <div className="col-9">
                            <div className="venmediaHeader">Social Media Paket</div>
                            <div>Nirgends treffen sich mehr Menschen als in den Social Media Kanälen. Deshalb sind die sozialen Netzwerke heute der Schlüssel zum unternehmerischen Erfolg. Für die Verwendung in den sozialen Medien ist eine Videolänge von 30 Sekunden ideal. Damit Sie so einfach wie möglich Reichweite generieren können, erhalten Sie Ihr Erklärvideo zusätzlich in einem smartphonefreundlichen Format.</div>
                        </div>
                    </div>
                </div>
            </div>
            <img src={welleImg} style={{width: '100%', marginBottom: -7}}/>
        </div>
    )
}
export default Form