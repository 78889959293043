import styles from '../Form.module.css';
import React from "react";
import img1 from "./img1.png";
import img2 from "./img2.png";
import KontaktForm from "../../kontakt/Form";
import {Bounce, Slide, Zoom} from "react-awesome-reveal";
function Form(props) {
    return (
        <div>

            <div className={styles.top}>
                <div className="wrapper">
                    <div className="grid">
                        <div className="col-4">
                            <div style={{textAlign: 'center', width: '100%'}}>
                                <img src={img1} style={{width: '100%'}}/>
                            </div>
                        </div>
                        <div className="col-8">
                            <div>
                                <span className={styles.label}>Prozesserklärung</span>
                                <br/>
                                <br/>
                                <div>
                                    Komplexe Prozesse in Textform zu erklären, kann oft eine Herausforderung sein. Deshalb bieten wir Ihnen die Möglichkeit, Ihre Kundschaft mit einfach verständlichen Erklärvideos bei der Einarbeitung zu unterstützen.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="wrapper">
                    <div className={styles.items}>
                        <div className="grid">
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <Bounce triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '120px', maxWidth: '100%'}}/></div>
                                        <div className={"lg:col-12 col-8"}>
                                            <div className={styles.itemContent}>
                                                Unser Ziel ist es, Ihre komplexen Prozesse auf eine klare und unterhaltsame Art zu visualisieren, um sicherzustellen, dass Ihre Zielgruppe die Informationen leicht versteht und sich in kürzester Zeit in das Thema einarbeiten kann.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={"p-lg-4 p-col-12 " + styles.item}>
                                <Bounce delay="50" triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '140px', maxWidth: '100%'}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={styles.itemContent}>
                                                Wenn Sie Ihre Prozesse mit unseren animierten Erklärvideos erklären, bieten Sie Ihrer Kundschaft eine effiziente Möglichkeit, sich neues Wissen anzueignen und gleichzeitig Ihre Botschaft ansprechend zu vermitteln.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={"p-lg-4 p-col-12 " + styles.item}>
                                <Bounce delay="100" triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '160px', maxWidth: '100%'}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={styles.itemContent}>
                                                Lassen Sie uns Ihnen dabei helfen, Ihre Inhalte lebendig und überzeugend darzustellen. Kontaktieren Sie uns einfach, und wir freuen uns darauf, gemeinsam mit Ihnen ein beeindruckendes Erklärvideo zu gestalten.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">

                <div  style={{textAlign: 'center'}}>

                    <div className="venmediaHeader">Komplexe Prozesse Im Verständlichen Videoformat!</div>
                    <div >
                        Unser Ansatz besteht darin, die Faktoren und Zusammenhänge Ihrer Prozesse durch anschauliche Animationen sichtbar zu machen. Sie müssen uns lediglich sämtliche vorhandene Unterlagen zusenden, auf deren Grundlage wir unser eigenes Research durchführen. Dadurch können wir ein maßgeschneidertes Konzept entwickeln, das perfekt auf Ihre Anforderungen zugeschnitten ist.
                    </div>

                </div>
            </div>

            <KontaktForm/>
        </div>
    )
}
export default Form