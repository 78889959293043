import styles from './List.module.css';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Bounce, Fade, Slide} from "react-awesome-reveal";
import TrackVisibility from "react-on-screen";
import pauseImg from "./pause.png";
import playImg from "./play.png";
import img01 from "./01-de-w-02 - Anja.png";
import img02 from "./02-de-w-01 - Anna.png";
import img03 from "./03-us-w-02 - Emma.png";
import img04 from "./04-uk-w-02 - Eva.png";
import img05 from "./05-de-m-08 - Fabian.png";
import img06 from "./06-uk-m-02 - Harry .png";
import img07 from "./07-uk-m-01 - Jacob.png";
import img08 from "./08-de-m-05 - Jonas.png";
import img09 from "./09-us-m-02 - Jordan.png";
import img10 from "./10-de-w-03 - Julia.png";
import img11 from "./11-de-m-02 - Jürgen.png";
import img12 from "./12-de-w-07 - Karolin.png";
import img13 from "./13-us-w-04 - Kate.png";
import img14 from "./14-de-m-04 - Kevin.png";
import img15 from "./15-de-w-13 - Laura.png";
import img16 from "./16-uk-m-03 - Lucas.jpeg";
import img17 from "./17-de-w-12 - Marie.png";
import img18 from "./18-de-m-09 - Matthias.png";
import img19 from "./19-us-w-03 - Natalie.png";
import img20 from "./20-de-m-12 - Patrick.png";
import img21 from "./21-us-m-04 - Robert.png";
import img22 from "./22-de-w-10 - Sabine.png";
import img23 from "./23-uk-w-01 - Sofia.png";
import img24 from "./24-de-w-11 - Susanne.png";
import img25 from "./25-de-m-03 - Tim.jpeg";
import img26 from "./26-us-m-01 - Victor.png";
import img27 from "./27-de-m-01 - Rayk.png";
import flagDeImg from "./flag_de.png";
import flagUkImg from "./flag_uk.png";
import flagUsImg from "./flag_usa.png";
import {Button} from "primereact/button";

function List(props) {

    const [filterLanguage, setFilterLanguage] = useState();
    const [filterGender, setFilterGender] = useState();
    const [voice, setVoice] = useState();


    const voices = [
        { img: img01, src: "/voices/01-de-w-02 - Anja.mp3", language: 'DE', gender: 'FEMALE', name: 'Anja'},
        { img: img02, src: "/voices/02-de-w-01 - Anna.mp3", language: 'DE', gender: 'FEMALE', name: 'Anna'},
        { img: img03, src: "/voices/03-us-w-02 - Emma.mp3", language: 'US', gender: 'FEMALE', name: 'Emma'},
        { img: img04, src: "/voices/04-uk-w-02 - Eva.mp3", language: 'UK', gender: 'FEMALE', name: 'Eva'},
        { img: img05, src: "/voices/05-de-m-08 - Fabian.mp3", language: 'DE', gender: 'MALE', name: 'Fabian'},
        { img: img06, src: "/voices/06-uk-m-02 - Harry .mp3", language: 'UK', gender: 'MALE', name: 'Harry'},
        { img: img07, src: "/voices/07-uk-m-01 - Jacob.mp3", language: 'UK', gender: 'MALE', name: 'Jacob'},
        { img: img08, src: "/voices/08-de-m-05 - Jonas.mp3", language: 'DE', gender: 'MALE', name: 'Jonas'},
        { img: img09, src: "/voices/09-us-m-02 - Jordan.mp3", language: 'US', gender: 'MALE', name: 'Jordan'},
        { img: img10, src: "/voices/10-de-w-03 - Julia.mp3", language: 'DE', gender: 'FEMALE', name: 'Julia'},
        { img: img11, src: "/voices/11-de-m-02 - Jürgen.mp3", language: 'DE', gender: 'MALE', name: 'Jürgen'},
        { img: img12, src: "/voices/12-de-w-07 - Karolin.mp3", language: 'DE', gender: 'FEMALE', name: 'Karolin'},
        { img: img13, src: "/voices/13-us-w-04 - Kate.mp3", language: 'US', gender: 'FEMALE', name: 'Kate'},
        { img: img14, src: "/voices/14-de-m-04 - Kevin.mp3", language: 'DE', gender: 'MALE', name: 'Kevin'},
        { img: img15, src: "/voices/15-de-w-13 - Laura.mp3", language: 'DE', gender: 'FEMALE', name: 'Laura'},
        { img: img16, src: "/voices/16-uk-m-03 - Lucas.mp3", language: 'UK', gender: 'MALE', name: 'Lucas'},
        { img: img17, src: "/voices/17-de-w-12 - Marie.mp3", language: 'DE', gender: 'FEMALE', name: 'Marie'},
        { img: img18, src: "/voices/18-de-m-09 - Matthias.mp3", language: 'DE', gender: 'MALE', name: 'Matthias'},
        { img: img19, src: "/voices/19-us-w-03 - Natalie.mp3", language: 'US', gender: 'FEMALE', name: 'Natalie'},
        { img: img20, src: "/voices/20-de-m-12 - Patrick.mp3", language: 'DE', gender: 'MALE', name: 'Patrick'},
        { img: img21, src: "/voices/21-us-m-04 - Robert.mp3", language: 'US', gender: 'MALE', name: 'Robert'},
        { img: img22, src: "/voices/22-de-w-10 - Sabine.mp3", language: 'DE', gender: 'FEMALE', name: 'Sabine'},
        { img: img23, src: "/voices/23-uk-w-01 - Sofia.mp3", language: 'UK', gender: 'FEMALE', name: 'Sofia'},
        { img: img24, src: "/voices/24-de-w-11 - Susanne.mp3", language: 'DE', gender: 'FEMALE', name: 'Susanne'},
        { img: img25, src: "/voices/25-de-m-03 - Tim.mp3", language: 'DE', gender: 'MALE', name: 'Tim'},
        { img: img26, src: "/voices/26-us-m-01 - Victor.mp3", language: 'US', gender: 'MALE', name: 'Victor'},
        { img: img27, src: "/voices/27-de-m-01 - Rayk.mp3", language: 'DE', gender: 'MALE', name: 'Rayk'},
    ]


    return (
        <div style={{background: 'var(--venmediaColor2)'}}>
            <div style={{position: 'absolute'}}>
                <div style={{borderRadius: 150, marginTop: 400, marginLeft: -150, height: 200, width: 200, background: '#51516E'}}/>
            </div>
            <div style={{position: 'absolute'}}>
                <div style={{borderRadius: 150, marginTop: 400, marginLeft: 150, height: 30, width: 30, background: '#51516E'}}/>
            </div>
            <div className="wrapper" ref={props.childRef} >

                <center>
                    <div className="venmediaHeader2">Unsere Stimmen</div>
                    <div>Ein gutes Erklärvideo benötigt eine tolle Stimme - Hören Sie unsere Sprecher, bevor Sie ein Video erstellen lassen.</div>
                    <br/>
                    {voice && (
                        <div>
                            <div style={{height: '90px', borderRadius: 90}}>
                                <img src={voice.img} style={{height: '100%', borderRadius: 90}}/>
                            </div>
                            <div>
                                <b>{voice.name}</b>
                                <span> spricht </span>
                                <b>{voice.language == 'DE' && 'Deutsch'}</b>
                                <b>{voice.language == 'UK' && 'English-UK'}</b>
                                <b>{voice.language == 'US' && 'English'}</b>
                            </div>
                            {voice && (
                                <audio controls src={voice.src}/>
                            )}
                        </div>
                    )}
                </center>


                <div >
                    <Button className="p-button-rounded p-button-outlined" style={{color: '#FFFFFF', marginLeft: 10}} label="Alle" onClick={() => setFilterGender(null)}/>
                    <Button className="p-button-rounded p-button-outlined" style={{color: '#FFFFFF', marginLeft: 10}} label="Männlich" onClick={() => setFilterGender('MALE')}/>
                    <Button className="p-button-rounded p-button-outlined" style={{color: '#FFFFFF', marginLeft: 10}} label="Weiblich" onClick={() => setFilterGender('FEMALE')}/>
                    <Button className="p-button-rounded p-button-outlined" style={{color: '#FFFFFF', marginLeft: 10, float: 'right'}} label="DE" onClick={() => setFilterLanguage('DE')}/>
                    <Button className="p-button-rounded p-button-outlined" style={{color: '#FFFFFF', marginLeft: 10, float: 'right'}} label="UK" onClick={() => setFilterLanguage('UK')}/>
                    <Button className="p-button-rounded p-button-outlined" style={{color: '#FFFFFF', marginLeft: 10, float: 'right'}} label="USA" onClick={() => setFilterLanguage('US')}/>
                    <Button className="p-button-rounded p-button-outlined" style={{color: '#FFFFFF', marginLeft: 10, float: 'right'}} label="Alle" onClick={() => setFilterLanguage(null)}/>
                </div>

                <div style={{cursor: 'pointer', textAlign: 'center', paddingTop: 20}}>
                    {voices.map( indexedVoice => {
                        if(
                            (filterLanguage == null || indexedVoice.language == filterLanguage) &&
                            (filterGender == null || indexedVoice.gender == filterGender)
                        ) {
                            return (
                                <span style={{padding: 10, display: 'inline-block'}} onClick={() => setVoice(indexedVoice)}>
                            <div style={{height: '90px', width: '90px', opacity: (voice && voice.name != indexedVoice?.name) ? 0.3 : null}}>
                                <div style={{position: 'absolute'}}>
                                    <img src={indexedVoice.img} style={{height: '90px', borderRadius: 90}}/>
                                </div>
                                <div style={{position: 'absolute'}}>
                                    {indexedVoice.language == 'DE' && (<img src={flagDeImg} style={{height: '25px', marginTop: '65px', marginLeft: '65px'}}/>)}
                                    {indexedVoice.language == 'UK' && (<img src={flagUkImg} style={{height: '25px', marginTop: '65px', marginLeft: '65px'}}/>)}
                                    {indexedVoice.language == 'US' && (<img src={flagUsImg} style={{height: '25px', marginTop: '65px', marginLeft: '65px'}}/>)}
                                </div>
                            </div>
                            <div style={{textAlign: 'center', fontSize: 18, fontWeight: 700}}>{indexedVoice.name}</div>
                        </span>
                            )
                        }
                    })}
                </div>


            </div>
        </div>
    );
}
export default List