import styles from './Form.module.css';
import {Bounce} from "react-awesome-reveal";
import React from "react";
function Form(props) {
    return (
        <div className="wrapper">
            <div ref={props.childRef}>
                <div style={{background: 'rgba(255,255,255,1)', padding: '15px 30px 15px 30px', borderRadius: '30px'}}>

                    <center>
                        Qualitätssicherung
                    </center>
                </div>
            </div>
        </div>
    )
}

export default Form