import styles from "./Form.module.css";
import React, {useState} from "react";
import imgClassic from "./img/classic.gif";
import imgHighEnd from "./img/highEnd.gif";

function Form(props) {

    const [selectedFirstContentIndex, setSelectedFirstContentIndex] = useState(99);
    const [selectedSecondContentIndex, setSelectedSecondContentIndex] = useState(null);

    return (
        <div className="wrapper">
            <div className="grid" style={{padding: 10}}>
                <div className="lg:col-1 col-12"></div>
                <div className={"lg:col-5 col-12 " + styles.item + " " + styles.silverBackground}>
                    <div className={styles.itemHeader}>Classic Animation</div>

                    <img className={styles.img} src={imgClassic}/>

                    <div className={styles.itemHeaderContent}>
                        Fokussiert sich auf das Wesentliche. Dabei verzichtet es auf alles Überflüssige und stellt sicher, dass Ihre Botschaft klar und unmissverständlich beim Zuschauer ankommt.
                    </div>

                    <div className={styles.contentHeader} onClick={() => setSelectedFirstContentIndex(selectedFirstContentIndex === 0 ? 99 : 0)}>
                        Klare Botschaften, einfache Animationen
                    </div>
                    <div className={selectedFirstContentIndex === 0 ? styles.fadeIn + ' ' + styles.content : styles.vennoxInvisible}>
                        Ein Erklärfilm, der sich auf die Essenz konzentriert. Protagonisten und Produkte stehen im Vordergrund, unterstützt durch klare Illustrationen und präzise Animationen. Ihre Botschaft wird klar und deutlich vermittelt.
                    </div>

                    <div className={styles.contentHeader} onClick={() => setSelectedFirstContentIndex(selectedFirstContentIndex === 1 ? 99 : 1)}>
                        Direkt und auf den Punkt gebracht
                    </div>
                    <div className={selectedFirstContentIndex === 1 ? styles.fadeIn + ' ' + styles.content : styles.vennoxInvisible}>
                        Mit minimalem visuellem Aufwand, aber maximaler Wirkung. Ein fokussierter Stil, der nicht ablenkt und den Zuschauer sofort auf die wichtigsten Inhalte lenkt.
                    </div>


                    <div className={styles.contentHeader} onClick={() => setSelectedFirstContentIndex(selectedFirstContentIndex === 2 ? 99 : 2)}>
                        Illustrationen, die Ihre Geschichte erzählen
                    </div>
                    <div className={selectedFirstContentIndex === 2 ? styles.fadeIn + ' ' + styles.content : styles.vennoxInvisible}>
                        Für die Charaktere, Szenen und Gegenstände erstellen wir klare, einfache Grafiken, die Ihre Geschichte lebendig machen und direkt bei Ihrem Publikum ankommen.
                    </div>


                </div>
                <div className={"lg:col-5 col-12 " + styles.item + " " + styles.silverBackground}>
                    <div className={styles.itemHeader}>High-End Animation</div>

                    <img className={styles.img} src={imgHighEnd}/>

                    <div className={styles.itemHeaderContent}>
                        Dynamische Hintergründe, liebevoll gestaltete Charaktere und beeindruckende Filmeffekte, die faszinieren. Perfekt abgestimmt auf Ihre hohen Ansprüche.
                    </div>

                    <div className={styles.contentHeader} onClick={() => setSelectedSecondContentIndex(selectedSecondContentIndex === 0 ? 99 : 0)}>
                        Premium-Design mit Wow-Effekt
                    </div>
                    <div className={selectedSecondContentIndex === 0 ? styles.fadeIn + ' ' + styles.content : styles.vennoxInvisible}>
                        Aufwendige, stilvolle Animationen, die Ihre Marke in den Vordergrund stellen. Von individuellen Hintergründen bis hin zu komplexen Effekten – hier erleben Sie Animation auf höchstem Niveau.
                    </div>


                    <div className={styles.contentHeader} onClick={() => setSelectedSecondContentIndex(selectedSecondContentIndex === 1 ? 99 : 1)}>
                        Detailreiche Animationen, die begeistern
                    </div>
                    <div className={selectedSecondContentIndex === 1 ? styles.fadeIn + ' ' + styles.content : styles.vennoxInvisible}>
                        Lebendige Hintergründe und detailverliebte Charaktere fesseln den Betrachter. Jede Szene ist mit Tiefenschärfe, Licht- und Schatteneffekten versehen, um ein echtes Premium-Erlebnis zu schaffen.
                    </div>


                    <div className={styles.contentHeader} onClick={() => setSelectedSecondContentIndex(selectedSecondContentIndex === 2 ? 99 : 2)}>
                        Visuelle Erlebnisse in jeder Sekunde
                    </div>
                    <div className={selectedSecondContentIndex === 2 ? styles.fadeIn + ' ' + styles.content : styles.vennoxInvisible}>
                        Jeder Moment ist ein Hingucker: Bewegungen, die fließend sind, und Effekte, die Ihre Geschichte mit Emotionen und Dynamik erzählen – ein Fest für die Sinne und die perfekte Umsetzung Ihrer Vision.
                    </div>


                </div>
                <div className="lg:col-1 col-12"></div>
            </div>
        </div>

    )
}

export default Form