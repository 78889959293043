import styles from '../Form.module.css';
import React from "react";
import img1 from "./img1.png";
import img2 from "./img2.png";
import KontaktForm from "../../kontakt/Form";
import {Bounce, Slide, Zoom} from "react-awesome-reveal";
function Form(props) {
    return (
        <div>
            <div className={styles.top}>
                <div className="wrapper">
                    <div className="grid">
                        <div className="col-4">
                            <div style={{textAlign: 'center', width: '100%'}}>
                                <img src={img1} style={{width: '100%'}}/>
                            </div>
                        </div>
                        <div className="col-8">
                            <div>
                                <span className={styles.label}>Recurting</span>
                                <br/>
                                <br/>
                                <div>
                                    Steigern Sie die Effizienz Ihrer Personalsuche mit unseren maßgeschneiderten Erklärvideos. Denn wir sind überzeugt: Ein Unternehmen ist nur so gut wie seine Mitarbeitenden!
                                    <br/>
                                    <br/>
                                    Stellen Sie sich vor, Sie könnten potenziellen Mitarbeitenden Ihr Unternehmen in einem ansprechenden Erklärvideo präsentieren. Genau das bieten wir Ihnen! Unsere maßgeschneiderten Erklärvideos sind speziell darauf ausgerichtet, Ihr Unternehmen im Arbeitsmarkt hervorzuheben und die besten Seiten Ihrer Organisation zu präsentieren.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="wrapper">
                    <div className={styles.items}>
                        <div className="grid">
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <Bounce triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '120px', maxWidth: '100%'}}/></div>
                                        <div className={"lg:col-12 col-8"}>
                                            <div className={styles.itemContent}>
                                                Unser Ziel ist es, Ihre Fachkräfte von morgen zu begeistern und ihnen die vielfältigen Karriere-Möglichkeiten in Ihrem Unternehmen aufzuzeigen. Mit mitreißenden Animationen veranschaulichen wir die Vorteile eines Arbeitsplatzes bei Ihnen und schaffen somit Klarheit über die einzigartigen Möglichkeiten, die Ihre Firma zu bieten hat.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <Bounce delay="50" triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '140px', maxWidth: '100%'}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={styles.itemContent}>
                                                Unsere Experten gehen auf Ihre individuellen Wünsche ein und animieren Ihre Betriebs- oder Büroräume sowie andere Alleinstellungsmerkmale, um ein einzigartiges und fesselndes Video zu erstellen.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <Bounce delay="100" triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '160px', maxWidth: '100%'}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={styles.itemContent}>
                                                Nutzen Sie die Kraft der Visualisierung, um potenzielle Talente anzuziehen und Ihr Recruiting auf das nächste Level zu bringen. Mit einem Erklärvideo von Venmedia setzen Sie auf innovative und überzeugende Weise Ihre Arbeitgebermarke in Szene.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">

                <div  style={{textAlign: 'center'}}>

                    <div className="venmediaHeader">Mit Einem Erklärvideo Zu Neuen Mitarbeitenden!</div>
                    <div>Lassen Sie uns gemeinsam dafür sorgen, dass Ihr Unternehmen in der Personalbeschaffung heraussticht und Sie die besten Talente für sich gewinnen. Kontaktieren Sie uns noch heute und entdecken Sie, wie wir mit unseren animierten Erklärvideos Ihre Recruiting-Erfolge steigern können!</div>

                </div>
            </div>

            <KontaktForm/>
        </div>
    )
}
export default Form