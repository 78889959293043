import styles from ".//Form.module.css";
import element1 from "../avatar1.png"
import element2 from "../avatar2.png"
import element3 from "../avatar3.png"
import element4 from "../avatar4.png"
import element5 from "../avatar5.png"
import element6 from "../avatar6.png"
import element7 from "../avatar7.png"
import element8 from "../avatar8.png"
import {Slide, Zoom} from "react-awesome-reveal";
import React from "react";

function Form(props) {


    return (
        <div style={{marginBottom: -7}}>
            <center>
                <div style={{maxWidth: 900}}>
                    <Zoom triggerOnce>
                        <div style={{position: 'absolute'}}>
                            <img src={element2} style={{width: '100%'}}/>
                        </div>
                        <div style={{position: 'absolute'}}>
                            <img src={element6} style={{width: '100%'}}/>
                        </div>
                        <div style={{position: 'absolute'}}>
                            <img src={element8} style={{width: '100%'}}/>
                        </div>
                    </Zoom>




                    <Zoom triggerOnce delay={100}>
                        <div style={{position: 'absolute'}}>
                            <img src={element1} style={{width: '100%'}}/>
                        </div>
                        <div style={{position: 'absolute'}}>
                            <img src={element3} style={{width: '100%'}}/>
                        </div>
                        <div style={{position: 'absolute'}}>
                            <img src={element5} style={{width: '100%'}}/>
                        </div>
                        <div style={{position: 'absolute'}}>
                            <img src={element7} style={{width: '100%'}}/>
                        </div>
                    </Zoom>



                    <Zoom triggerOnce delay={200}>
                        <div>
                            <img src={element4} style={{width: '100%'}}/>
                        </div>
                    </Zoom>
                </div>
            </center>
        </div>
    )
}
export default Form