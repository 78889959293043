import styles from './Form.module.css';
import React from "react";
import {Bounce, Fade, Slide, Zoom} from "react-awesome-reveal";
import icon1 from "./icon1.png";
import icon2 from "./icon2.png";
import icon3 from "./icon3.png";
import {Button} from "primereact/button";

function Form(props) {


    const VisibilityTracker = (visibilityProps) => {
        return (
            <div className={ visibilityProps.isVisible ? styles.item : styles.itemTransition}>
                {visibilityProps.children}
            </div>);
    }


    return (
        <div style={{background: 'rgba(232,232,232,0)'}}>
            <div className="wrapper" ref={props.childRef} style={{paddingTop: 100, paddingBottom: 100}}>

                <Zoom direction="right" triggerOnce>
                    <div className={styles.contactForm}>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <div style={{fontSize: 80, paddingLeft: 20, paddingRight: 20, color: 'green'}}>
                                        <i style={{fontSize: 48}} className="pi pi-check"></i>
                                    </div>
                                </td>
                                <td>
                                    <div className="venmediaHeader">Ihre Kontaktanfrage ist bei uns eingegangen!</div>
                                    <div>Sie erhalten in kürze eine Bestätigungsmail.</div><br/>
                                    Nach Prüfung Ihrer Angaben, melden wir uns unverzüglich mit einem persönlichen Angebot an Sie.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Zoom>
            </div>
        </div>
    );
}
export default Form