import styles from "./Form.module.css";
import React, {useEffect, useState} from "react";
import welleBottomImg from './img/welleBottom.svg'
import imgStilSchattenJaFlat from './img/stilSchattenJaFlat.png'

function Form(props) {

    const [stilFlat, setStilFlat] = useState(false);
    const [stilCartoon, setStilCartoon] = useState(false);
    const [stilIsometrisch, setStilIsometrisch] = useState(false);
    const [stilSchatten, setStilSchatten] = useState(false);
    const [stilFarbverlauf, setStilFarbverlauf] = useState(false);
    const [stilReaumlicheTiefe, setStilReaumlicheTiefe] = useState(false);


    useEffect(() => {
        if(stilFlat) {
            setStilCartoon(false)
            setStilIsometrisch(false)
        }
    }, [stilFlat]);

    useEffect(() => {
        if (stilCartoon){
            setStilFlat(false)
            setStilIsometrisch(false)
        }
    }, [stilCartoon]);

    useEffect(() => {
        if (stilIsometrisch){
            setStilFlat(false)
            setStilCartoon(false)
        }
    }, [stilIsometrisch]);

    const ITEM_BUTTON = (props) => (
        <div
            className={props.selected ? styles.itemSelected : styles.item}
            onClick={() => props.onClick()}>
            {props.label}
        </div>
    )
    const ITEM_SMALL_BUTTON = (props) => (
        <div>
            <div className={styles.smallItemHeader}>{props.label}:</div>
            <div className={props.selected ? styles.smallItemSelected : styles.smallItem} onClick={() => props.onClick(true)}>Ja</div>
            <div className={!props.selected ? styles.smallItemSelected : styles.smallItem} onClick={() => props.onClick(false)}>Nein</div>
        </div>
    )

    return (
        <div>
            <img src={welleBottomImg} style={{width: '100%', marginTop: '-4%', marginBottom: '-5%'}}/>

            <div style={{padding: 40}}>
                <div className="grid">
                    <div className="col-9">
                        <center>
                        <img src={imgStilSchattenJaFlat} style={{width: '800px'}}/>
                        </center>
                    </div>
                    <div
                        className="col-3"
                        style={{
                            borderRadius: '30px',
                            background: 'var(--venmediaColor2)',
                            color: '#FFFFFF',
                            boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'
                        }}>

                        <div>
                            <div className={styles.header}>Stil</div>
                            <div className={styles.panel}>
                                <table style={{width: '100%'}}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <ITEM_BUTTON
                                                label="Flat"
                                                selected={stilFlat}
                                                onClick={() => setStilFlat(!stilFlat)}/>
                                        </td>
                                        <td style={{width: '20px'}}/>
                                        <td>
                                            <ITEM_SMALL_BUTTON
                                                label="Schatten"
                                                selected={stilSchatten}
                                                onClick={(value) => setStilSchatten(value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ITEM_BUTTON
                                                label="Cartoon"
                                                selected={stilCartoon}
                                                onClick={() => setStilCartoon(!stilCartoon)}/>
                                        </td>
                                        <td style={{width: '20px'}}/>
                                        <td>
                                            <ITEM_SMALL_BUTTON
                                                label="Farbverlauf"
                                                selected={stilFarbverlauf}
                                                onClick={(value) => setStilFarbverlauf(value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ITEM_BUTTON
                                                label="Isometrisch"
                                                selected={stilIsometrisch}
                                                onClick={() => setStilIsometrisch(!stilIsometrisch)}/>
                                        </td>
                                        <td style={{width: '20px'}}/>
                                        <td>
                                            <ITEM_SMALL_BUTTON
                                                label="Räumliche Tiefe"
                                                selected={stilReaumlicheTiefe}
                                                onClick={(value) => setStilReaumlicheTiefe(value)}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div className={styles.header}>Charackterbeispiele</div>
                            <div className={styles.panel}>
                                <table style={{width: '100%'}}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <div className={styles.item}>Figur A</div>
                                        </td>
                                        <td style={{width: '20px'}}/>
                                        <td>
                                            <div className={styles.smallItemHeader}>Schatten:</div>
                                            <div className={styles.smallItem}>Ja</div>
                                            <div className={styles.smallItem}>Nein</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={styles.item}>Figur B</div>
                                        </td>
                                        <td style={{width: '20px'}}/>
                                        <td>
                                            <div className={styles.smallItemHeader}>Farbverlauf:</div>
                                            <div className={styles.smallItem}>Ja</div>
                                            <div className={styles.smallItem}>Nein</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={styles.item}>Figur C</div>
                                        </td>
                                        <td style={{width: '20px'}}/>
                                        <td>
                                            <div className={styles.smallItemHeader}>Räumliche Tiefe:</div>
                                            <div className={styles.smallItem}>Ja</div>
                                            <div className={styles.smallItem}>Nein</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div className={styles.header}>Detailanimation</div>
                            <div className={styles.panel}>
                                <table style={{width: '100%'}}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <div className={styles.item}>Flat</div>
                                        </td>
                                        <td style={{width: '20px'}}/>
                                        <td>
                                            <div className={styles.smallItemHeader}>Schatten:</div>
                                            <div className={styles.smallItem} >Ja</div>
                                            <div className={styles.smallItem}>Nein</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={styles.item}>Cartoon</div>
                                        </td>
                                        <td style={{width: '20px'}}/>
                                        <td>
                                            <div className={styles.smallItemHeader}>Farbverlauf:</div>
                                            <div className={styles.smallItem}>Ja</div>
                                            <div className={styles.smallItem}>Nein</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={styles.item}>Isometrisch</div>
                                        </td>
                                        <td style={{width: '20px'}}/>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Form