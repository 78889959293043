import axios from "axios";

export const NOTIFICATION_PRV           = '/api/prv/notifications'

export const _GET = (url, filter) => axios.get(url + convertFilter(filter), createConfig(url)).then(processSuccess).catch(processError)
export const _POST = (url, body) => axios.post(url, body, createConfig(url)).then(processSuccess).catch(processError)
export const _PUT = (url, body) => axios.put(url, body, createConfig(url)).then(processSuccess).catch(processError)
export const _DELETE = (url) => axios.delete(url, createConfig(url)).then(processSuccess).catch(processError)

export const _DOWNLOAD = (url, filename, filter) => {
    fetch(url+convertFilter(filter), {
        headers: {
            Authorization: localStorage.getItem('jwtToken') ? `Bearer ${localStorage.getItem('jwtToken')}` : null
        }
    })
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            });
            //window.location.href = response.url;
        });
}

export const _INIT_EVENT = (auth, url) => {
    let config = {
        headers: {
            'Content-Type':'application/json'
        },
        timeout: 3600000
    }
    if(auth) {
        config = {
            headers: {
                'Content-Type':'application/json',
                Authorization: localStorage.getItem('jwtToken') ? `Bearer ${localStorage.getItem('jwtToken')}` : null
            },
            timeout: 3600000
        };
    }
    return axios.get(NOTIFICATION_PRV, config)
}

export const _INIT_NOTIFICATION = () => {
    _INIT_EVENT(true, NOTIFICATION_PRV)
        .then(response => {
            window.dispatchEvent(new CustomEvent('NOTIFICATION', {detail: response.data}))
            if(window != window.parent) {
                window.parent.dispatchEvent(new CustomEvent('NOTIFICATION', {detail: response.data}))
            }
            _INIT_NOTIFICATION()
        })
        .catch( error => {
            setTimeout(null, 10000);
            _INIT_NOTIFICATION()
        })
}

export const _VALID_EMPTY = (value) => {
    if(value == null || value == '' || value == ' ') { return true } else {return false}
}



const createConfig = (url, timeout) => {
    //axios.defaults.crossDomain = true;
    let config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('jwtToken') ? `Bearer ${localStorage.getItem('jwtToken')}` : null
            //'Access-Control-Allow-Origin': '*',
        },
        timeout: timeout
    }
    return config
}

const convertFilter = (filter) => {
    let queryFilter = ''
    if(filter != null) {
        let filterCopy = JSON.parse(JSON.stringify(filter))
        Object.keys(filterCopy).forEach((key) => (filterCopy[key] == null) && delete filterCopy[key]);
        queryFilter = '?' + new URLSearchParams(filterCopy).toString()
    }
    return queryFilter
}

const processSuccess = (response) => {
    if(response.headers['profile-jwt-token'] != null) {
        localStorage.setItem("jwtToken", response.headers['profile-jwt-token']);
        localStorage.setItem("jwtTokenMinutes", "59");
        localStorage.setItem("jwtTokenSeconds", "59");
        localStorage.setItem("role", response.headers['profile-role']);
        localStorage.setItem("username", response.headers['profile-username']);
        localStorage.setItem("profileId", response.headers['profile-id']);
        localStorage.setItem("profileFirstname", response.headers['profile-firstname']);
        localStorage.setItem("profileLastname", response.headers['profile-lastname']);
        localStorage.setItem("profileColor", response.headers['profile-color']);
    }

    window.dispatchEvent(new CustomEvent('NOTIFICATION_HTTP_SUCCESS_RESPONSE', {detail: response.data}))
    if(window != window.parent) {
        window.parent.dispatchEvent(new CustomEvent('NOTIFICATION_HTTP_SUCCESS_RESPONSE', {detail: response.data}))
    }
    return new Promise(function(resolve, reject) {
        resolve(Promise.resolve(response));
    })
}

const processError = (error) => {
    if(error.response.status == 401) {
        localStorage.clear()
        window.location.href = "/crm/ui/";
    } else if(error.response.status == 422) {
        window.dispatchEvent(new CustomEvent('NOTIFICATION_HTTP_BUSINESS_ERROR_RESPONSE', {detail: error.response}))
        if(window != window.parent) {
            window.parent.dispatchEvent(new CustomEvent('NOTIFICATION_HTTP_BUSINESS_ERROR_RESPONSE', {detail: error.response}))
        }
        console.log(error)
    } else {
        window.dispatchEvent(new CustomEvent('NOTIFICATION_HTTP_ERROR_RESPONSE', {detail: error.response}))
        if(window != window.parent) {
            window.parent.dispatchEvent(new CustomEvent('NOTIFICATION_HTTP_ERROR_RESPONSE', {detail: error.response}))
        }
        console.log(error)
    }
    return Promise.reject(error)
}


