export const EventEmitter = {
    events: {},
    dispatchEvent: function (event, data) {
        if(!this.events[event])
            return
        this.events[event].forEach(callback => callback(data))
    },
    addEventListener: function (event, callback) {
        if(!this.events[event])
            this.events[event] = []
        this.events[event].push(callback)
    },
    removeEventListener: function (event) {
        this.events[event] = []
    }
}