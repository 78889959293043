import React, {Component} from 'react';
import styles from './Form.module.css';
import imgInfSprecherstimmen from "./imgInfSprecherstimmen.png";
import imgInfPaket from "./imgInfPaket.png";
import imgInfKontakt from "./imgInfKontakt.png";

import imgSchulung from "./imgSchulung.png";
import imgRecuring from "./imgRecuring.png"
import imgSoftware from "./imgSoftware.png";
import imgLead from "./imgLead.png";
import imgProzess from "./imgProzess.png";
import imgVenclip from "./imgVenclip.png";

import imgUeberTeam from "./imgUeberTeam.png";
import imgUeberKarriere from "./imgUeberKarriere.png";
import imgUeberReferenzen from "./imgUeberReferenzen.png";

import imgRechImpressum from "./imgRechImpressum.png";
import imgRechtAgb from "./imgRechtAgb.png";
import imgRechtDatenschutz from "./imgRechtDatenschutz.png";

import element01 from "./Element01.png";
import element02 from "./Element02.png";
import element09 from "./Element09.png";
import element12 from "./Element12.png";
import {AttentionSeeker} from "react-awesome-reveal";

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <React.Fragment>
            <div style={{background: 'var(--venmediaColor2)'}}>
                <br/>
                <br/>
                <center>
                    <AttentionSeeker duration={2000}>

                        <div className={styles.item}>

                            <div className="venmediaHeader2" style={{color: 'var(--venmediaColor1)'}}>Sie haben Fragen?</div>
                            <div  style={{paddingBottom: 15}}>Lassen Sie uns persönlich über Ihr Thema sprechen und gemeinsam die optimale Lösung für Sie finden.</div>
                            <div className={styles.header} style={{color: 'var(--venmediaColor1)', paddingBottom: 0}}>Kontakt</div>
                            <table cellPadding={5}>
                                <tbody>
                                <tr>
                                    <td style={{textAlign: 'center'}}><img src={element01} className={styles.element}/></td>
                                    <td>06171 951 80 86</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'center'}}><img src={element02} className={styles.element}/></td>
                                    <td><a style={{color: '#FFFFFF', textDecoration: 'none'}} href="mailto:info@venmedia.de">info@venmedia.de</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </AttentionSeeker>
                </center>
                <br/>
                <br/>
            </div>

            <div style={{background: 'var(--venmediaColor1)'}}>
                <center>
                    <div style={{color: '#FFFFFF', padding: 10}}/>
                </center>
            </div>
            <div style={{background: '#292929', color: '#FFFFFF'}}>
                <div className="wrapper" style={{paddingBottom: 0, maxWidth: '90.25rem'}}>
                    <div className="grid">
                        <div className="lg:col-3 col-12">
                            <center>
                                <a
                                    href="https://www.provenexpert.com/venmedia/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                                    title="Kundenbewertungen &amp; Erfahrungen zu VENmedia. Mehr Infos anzeigen."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <img style={{maxHeight: '200px', maxWidth: '100%'}} src="https://www.provenexpert.com/images/de-de/widget/circle/widget.png" alt="Kundenbewertungen &amp; Erfahrungen zu VENmedia. Mehr Infos anzeigen."/>
                                </a>
                                <br/>
                                <br/>
                                <div>
                                    <i className="pi pi-instagram" style={{padding: 10, fontSize: '2em', cursor: 'pointer'}} onClick={() => window.open("https://www.instagram.com/venmedia_de/", '_blank')}/>
                                    <i className="pi pi-youtube" style={{padding: 10, fontSize: '2em', cursor: 'pointer'}} onClick={() => window.open("https://www.youtube.com/channel/UCo0_vVqcoF1Cw070sgJxY1Q", '_blank')}/>
                                    <i className="pi pi-twitter" style={{padding: 10, fontSize: '2em', cursor: 'pointer'}} onClick={() => window.open("https://twitter.com/VENmedia_de", '_blank')}/>
                                </div>
                            </center>

                        </div>
                        <div className="lg:col-9 col-12">

                            <div className="grid">
                                { /*
                                <div className="p-col-6 p-md-3">
                                    <table cellPadding={5}>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={styles.header} style={{color: 'var(--venmediaColor1)'}}>Leistungen</div>
                                            </td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/leistungen/schulungsvideo"}>
                                            <td style={{textAlign: 'center'}}><img src={imgSchulung} className={styles.element}/></td>
                                            <td>Schulungsvideo</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/leistungen/recurtingvideo"}>
                                            <td style={{textAlign: 'center'}}><img src={imgRecuring} className={styles.element}/></td>
                                            <td>Recurtingvideo</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/leistungen/softwarevideo"}>
                                            <td style={{textAlign: 'center'}}><img src={imgSoftware} className={styles.element}/></td>
                                            <td>Softwarevideo</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/leistungen/leadgenerierung"}>
                                            <td style={{textAlign: 'center'}}><img src={imgLead} className={styles.element}/></td>
                                            <td>Leadgenerierung</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/leistungen/prozesserklaerung"}>
                                            <td style={{textAlign: 'center'}}><img src={imgProzess} className={styles.element}/></td>
                                            <td>Prozesserklärung</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/leistungen/venclip"}>
                                            <td style={{textAlign: 'center'}}><img src={imgVenclip} className={styles.element}/></td>
                                            <td>Venclip</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                */}
                                <div className="md:col-3 col-6">
                                    <table cellPadding={5}>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={styles.header} style={{color: 'var(--venmediaColor1)'}}>Informationen</div>
                                            </td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/sprecherstimmen"}>
                                            <td style={{textAlign: 'center'}}><img src={imgInfSprecherstimmen} className={styles.element}/></td>
                                            <td>Sprecherstimmen</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/preise"}>
                                            <td style={{textAlign: 'center'}}><img src={imgInfPaket} className={styles.element}/></td>
                                            <td>Pakete</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/kontakt"}>
                                            <td style={{textAlign: 'center'}}><img src={imgInfKontakt} className={styles.element}/></td>
                                            <td>Kontakt</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="md:col-3 col-6">
                                    <table cellPadding={5}>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={styles.header} style={{color: 'var(--venmediaColor1)'}}>Über uns</div>
                                            </td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/team"}>
                                            <td style={{textAlign: 'center'}}><img src={imgUeberTeam} className={styles.element}/></td>
                                            <td>Das Team</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/karriere"}>
                                            <td style={{textAlign: 'center'}}><img src={imgUeberKarriere} className={styles.element}/></td>
                                            <td>Karriere</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/referenzen"}>
                                            <td style={{textAlign: 'center'}}><img src={imgUeberReferenzen} className={styles.element}/></td>
                                            <td>Referenzen</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="md:col-3 col-6">
                                    <table cellPadding={5}>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={styles.header} style={{color: 'var(--venmediaColor1)'}}>Rechtliches</div>
                                            </td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/impressum"}>
                                            <td style={{textAlign: 'center'}}><img src={imgRechImpressum} className={styles.element}/></td>
                                            <td>Impressum</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/agb"}>
                                            <td style={{textAlign: 'center'}}><img src={imgRechtAgb} className={styles.element}/></td>
                                            <td>AGB</td>
                                        </tr>
                                        <tr className={styles.content} onClick={() => window.location.href = "/datenschutz"}>
                                            <td style={{textAlign: 'center'}}><img src={imgRechtDatenschutz} className={styles.element}/></td>
                                            <td>Datenschutz</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>











                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td style={{verticalAlign: 'top'}}>

                            </td>
                            <td style={{verticalAlign: 'top'}}>

                            </td>
                            <td style={{verticalAlign: 'top'}}>

                            </td>
                            <td style={{verticalAlign: 'top'}}>


                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>

            </div>


            <div style={{background: 'var(--venmediaColor-2)'}}>

                <center>
                    <div style={{color: '#FFFFFF', padding: 10}}>Copyright © 2022</div>
                </center>
            </div>
        </React.Fragment>
    }
}

export default Form;
