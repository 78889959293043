import {Bounce, Slide, Zoom} from "react-awesome-reveal";
import styles from "./Form.module.css";
import paketIcon from "./paketIcon.png";
import icon1 from "../area2/icon1.png";
import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {RadioButton} from "primereact/radiobutton";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {AppApi} from "../../AppApi";
import PlanetAnimation from "../../ui/eeplanetanimation/Form";
import welleTop from "./welleTop.svg";

function ItemValueRow(props) {
    return (
        <tr>
            <td><i className="pi pi-check" style={{paddingLeft: 10, paddingRight: 10, color: 'var(--venmediaColor1)'}}/></td>
            <td>{props.children}</td>
        </tr>
    )
}
function Form(props) {

    const [isTitleEmpty, setIsTitleEmpty] = useState(false);
    const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
    const [isNameEmpty, setIsNameEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isTelephoneEmpty, setIsTelephoneEmpty] = useState(false);
    const [isSubjectEmpty, setIsSubjectEmpty] = useState(false);
    const [isMessageEmpty, setIsMessageEmpty] = useState(false);
    const [form, setForm] = useState({
        paket: null,
        title: 'MR',
        name: null,
        company: null,
        email: null,
        telephone: null,
        subject: null,
        message: null
    });

    function isEmpty(s){
        return s == null || s.trim().length == 0
    }

    const actions = {
        post: () => {
            let sendEmail = true

            if(isEmpty(form.title)){
                setIsTitleEmpty(true)
            } else {
                setIsTitleEmpty(false)
            }

            if(isEmpty(form.name)){
                setIsNameEmpty(true)
                sendEmail = false
            } else {
                setIsNameEmpty(false)
            }

            if(isEmpty(form.company)){
                setIsCompanyEmpty(true)
            } else {
                setIsCompanyEmpty(false)
            }

            if(form.email == null || !form.email.includes("@") || !form.email.includes(".")) {
                setIsEmailEmpty(true)
                sendEmail = false
            } else {
                if(
                    form.email.toLowerCase().includes("@web")
                    || form.email.toLowerCase().includes("@icloud")
                    || form.email.toLowerCase().includes("@gmx")
                    || form.email.toLowerCase().includes("@hotmail")
                    || form.email.toLowerCase().includes("@outlook")
                    || form.email.toLowerCase().includes("@googlemail")
                    || form.email.toLowerCase().includes("@gmail")
                    || form.email.toLowerCase().includes("@freenet")
                    || form.email.toLowerCase().includes("@t-online.de")
                    || form.email.toLowerCase().includes("@mnet-mail.")
                    || form.email.toLowerCase().includes("@yahoo")
                ) {
                    setIsEmailEmpty(true)
                    sendEmail = false
                    alert("Bitte verwenden Sie für geschäftliche Anfragen Ihre Geschäfts-Mailadresse anstelle Ihrer privaten E-Mail.\n" +
                        "Falls Sie persönliche Anliegen haben, möchten wir Sie bitten, uns unter der Rufnummer 06171 951 80 86 anzurufen, damit wir Ihnen sofort eine Beratung anbieten können.")
                } else {
                    setIsEmailEmpty(false)
                }
            }

            if(isEmpty(form.telephone)){
                setIsTelephoneEmpty(true)
                sendEmail = false
            } else {
                setIsTelephoneEmpty(false)
            }

            if(isEmpty(form.subject)){
                setIsSubjectEmpty(true)
            } else {
                setIsSubjectEmpty(false)
            }

            if(isEmpty(form.message)){
                setIsMessageEmpty(true)
            } else {
                setIsMessageEmpty(false)
            }

            if(sendEmail) {
                let postForm = {
                    token: 'setLater',
                    title: form.title,
                    companyName: form.company,
                    name: form.name,
                    email: form.email,
                    telephone: form.telephone,
                    fields: [
                        {
                            type: "TEXT",
                            value: form.subject,
                            label: "Betreff"
                        },
                        {
                            type: "TEXT",
                            value: form.message,
                            label: "Nachricht"
                        }
                    ]
                }

                if(form.paket == 'SILVER') {
                    postForm.token = '14a727ae-e654-404a-acb5-5fffe2388363'
                    AppApi.pub.form.post(postForm)
                        .then(response => {
                            window.location.href = "/danke/silver"
                        })
                        .catch(console.log)
                } else if(form.paket == 'GOLD') {
                    postForm.token = 'c1266583-0bba-4b82-914b-1bdf3df7dfda'
                    AppApi.pub.form.post(postForm)
                        .then(response => {
                            window.location.href = "/danke/gold"
                        })
                        .catch(console.log)
                } else if(form.paket == 'PREMIUM') {
                    postForm.token = '651ed065-ad35-49ee-b570-6d955b1a21d3'
                    AppApi.pub.form.post(postForm)
                        .then(response => {
                            window.location.href = "/danke/premium"
                        })
                        .catch(console.log)
                }
            }
        },
    }

    const silverContent = (
        <div>
            <div className={styles.itemHeader}>Silver</div>
            <br/>
            <div className={styles.itemTime}><img src={paketIcon} style={{height: '25px'}}/> 60 Sek</div>
            <center>
                <table>
                    <tbody>
                    <ItemValueRow>Legetrick, Flat oder Cartoon</ItemValueRow>
                    <ItemValueRow>Drehbuch-/ Konzepterstellung</ItemValueRow>
                    <ItemValueRow>Professioneller Sprechertext</ItemValueRow>
                    <ItemValueRow>Voice Over Ihrer Wahl</ItemValueRow>
                    <ItemValueRow>Postproduktion</ItemValueRow>
                    <ItemValueRow>Kommerzielle Rechte bei Abnahme</ItemValueRow>
                    <ItemValueRow>unbegrenzte Korrekturen frei</ItemValueRow>
                    <ItemValueRow>Hintergrundmusik</ItemValueRow>
                    <ItemValueRow>Professionelle Soundeffekte</ItemValueRow>
                    <ItemValueRow>15 Werktage Lieferzeit</ItemValueRow>
                    <ItemValueRow>bis 60 Sekunden Laufzeit</ItemValueRow>
                    <ItemValueRow>Abstimmung auf Ihre CI</ItemValueRow>
                    <ItemValueRow>Logoanimation</ItemValueRow>
                    </tbody>
                </table>
            </center>
            <br/>
        </div>
    )

    const goldContent = (
        <div>
            <div className={styles.itemHeader}>Gold</div>
            <br/>
            <div className={styles.itemTime}><img src={paketIcon} style={{height: '25px'}}/> 90 Sek</div>
            <center>
                <table>
                    <tbody>
                    <ItemValueRow>Legetrick, Flat oder Cartoon</ItemValueRow>
                    <ItemValueRow>Drehbuch-/ Konzepterstellung</ItemValueRow>
                    <ItemValueRow>Professioneller Sprechertext</ItemValueRow>
                    <ItemValueRow>Voice Over Ihrer Wahl</ItemValueRow>
                    <ItemValueRow>Postproduktion</ItemValueRow>
                    <ItemValueRow>Kommerzielle Rechte bei Abnahme</ItemValueRow>
                    <ItemValueRow>unbegrenzte Korrekturen frei</ItemValueRow>
                    <ItemValueRow>Hintergrundmusik</ItemValueRow>
                    <ItemValueRow>Professionelle Soundeffekte</ItemValueRow>
                    <ItemValueRow>18 Werktage Lieferzeit</ItemValueRow>
                    <ItemValueRow>bis 90 Sekunden Laufzeit</ItemValueRow>
                    <ItemValueRow>Abstimmung auf Ihre CI</ItemValueRow>
                    <ItemValueRow>Logoanimation</ItemValueRow>
                    </tbody>
                </table>
            </center>
            <br/>
        </div>
    )

    const premiumContent = (
        <div>
            <div className={styles.itemHeader}>Premium</div>
            <br/>
            <div className={styles.itemTime}><img src={paketIcon} style={{height: '25px'}}/> 120 Sek</div>

            <center>
                <table>
                    <tbody>
                    <ItemValueRow>Legetrick, Flat oder Cartoon</ItemValueRow>
                    <ItemValueRow>Drehbuch-/ Konzepterstellung</ItemValueRow>
                    <ItemValueRow>Professioneller Sprechertext</ItemValueRow>
                    <ItemValueRow>Voice Over Ihrer Wahl</ItemValueRow>
                    <ItemValueRow>Postproduktion</ItemValueRow>
                    <ItemValueRow>Kommerzielle Rechte bei Abnahme</ItemValueRow>
                    <ItemValueRow>unbegrenzte Korrekturen frei</ItemValueRow>
                    <ItemValueRow>Hintergrundmusik</ItemValueRow>
                    <ItemValueRow>Professionelle Soundeffekte</ItemValueRow>
                    <ItemValueRow>21 Werktage Lieferzeit</ItemValueRow>
                    <ItemValueRow>bis 120 Sekunden Laufzeit</ItemValueRow>
                    <ItemValueRow>Abstimmung auf Ihre CI</ItemValueRow>
                    <ItemValueRow>Logoanimation</ItemValueRow>
                    </tbody>
                </table>
            </center>
            <br/>
        </div>
    )

    return (
        <div>
            <img src={welleTop} style={ {marginBottom: '-1%'}}/>
            <div style={{background: 'rgba(255,255,255,1)'}}>

                <Dialog
                    showHeader={false}
                    visible={form.paket != null}
                    style={{ width: '100%', maxWidth: 1024, margin: 10 }}>

                    <div style={{height: 50}}>
                        <span style={{fontSize: 22, fontWeight: 600, float: 'left'}}>Jetzt unverbindlich und kostenlos anfragen</span>
                        <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger"
                            style={{float: 'right'}}
                            onClick={() => setForm({...form, paket: null})}
                        />
                    </div>
                    <div style={{width: '100%', position: 'absolute'}}>
                        <center>
                            <Bounce>
                                <div className={styles.backgroundPermanent} package={form.paket} style={{borderRadius: 150, marginTop: 40, height: 150, width: 150}}/>
                            </Bounce>
                        </center>
                    </div>
                    <div style={{width: '100%', position: 'absolute'}}>
                        <center>
                            <div className={styles.backgroundPermanent} package={form.paket} style={{borderRadius: 150, marginTop: 180, marginLeft: 75, height: 50, width: 50}}/>
                        </center>
                    </div>
                    <div style={{width: '100%', position: 'absolute'}}>
                        <center>
                            <div className={styles.backgroundPermanent} package={form.paket} style={{borderRadius: 150, marginTop: 250, marginLeft: -75, height: 50, width: 50}}/>
                        </center>
                    </div>
                    <PlanetAnimation/>
                    <Bounce>
                        <div className="grid">
                            <div className={"lg-4 p-d-none p-d-lg-inline-flex " + styles.item + " " + styles.backgroundPermanent}
                                 package={form.paket}>
                                {form.paket == 'SILVER' && silverContent}
                                {form.paket == 'GOLD' && goldContent}
                                {form.paket == 'PREMIUM' && premiumContent}
                            </div>

                            <div className="lg:col-8 col-12 ">
                                <div className="grid">
                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, color: isTitleEmpty ? 'red' : null}}>Anrede:</div>
                                        <div>
                                            <RadioButton inputId="titleMale" value="MR" name="city" onChange={(event) => setForm({...form, title: event.value})} checked={form.title === 'MR'} />
                                            <label htmlFor="titleMale" style={{paddingLeft:5, paddingRight:20}}>Herr</label>
                                            <RadioButton inputId="titleFemale" value="MRS" name="city" onChange={(event) => setForm({...form, title: event.value})} checked={form.title === 'MRS'} />
                                            <label htmlFor="titleFemale" style={{paddingLeft:5, paddingRight:20}}>Frau</label>
                                            <RadioButton inputId="titleDivers" value="OTHER" name="city" onChange={(event) => setForm({...form, title: event.value})} checked={form.title === 'OTHER'} />
                                            <label htmlFor="titleDivers" style={{paddingLeft:5, paddingRight:20}}>Divers</label>
                                        </div>
                                    </div>

                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                    </div>
                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, color: isNameEmpty ? 'red' : null}}>Name:</div>
                                        <InputText
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.name}
                                            placeholder="Name"
                                            onChange={(event) => setForm({...form, name: event.target.value})}/>
                                    </div>


                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, color: isCompanyEmpty ? 'red' : null}}>Firma:</div>
                                        <InputText
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.company}
                                            placeholder="Firma"
                                            onChange={(event) => setForm({...form, company: event.target.value})}/>

                                    </div>
                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, color: isEmailEmpty ? 'red' : null}}>Email:</div>
                                        <InputText
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.email}
                                            placeholder="Email"
                                            onChange={(event) => setForm({...form, email: event.target.value})}/>

                                    </div>
                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, color: isTelephoneEmpty ? 'red' : null}}>Telefon:</div>
                                        <InputText
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.telephone}
                                            placeholder="Telefon"
                                            onChange={(event) => setForm({...form, telephone: event.target.value})}/>

                                    </div>
                                    <div className="col-12" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, color: isSubjectEmpty ? 'red' : null}}>Betreff:</div>
                                        <InputText
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.subject}
                                            placeholder="Betreff"
                                            onChange={(event) => setForm({...form, subject: event.target.value})}/>

                                        <div style={{paddingTop: 10, color: isMessageEmpty ? 'red' : null}}>Nachricht:</div>
                                        <InputTextarea
                                            rows={5}
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.message}
                                            placeholder="Nachricht"
                                            onChange={(event) => setForm({...form, message: event.target.value})}/>
                                        <div style={{paddingTop: 10, float: 'right'}}>
                                            <Button label="Kontakt Formular senden" onClick={() => actions.post()}/>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </Bounce>


                </Dialog>

                <div className="wrapper">
                    <div ref={props.childRef}>
                        <Slide delay="20" triggerOnce>
                            <div className="venmediaHeader">Das sind unsere Pakete</div>
                        </Slide>
                        <br/>
                        <br/>
                        <Slide triggerOnce>
                            <center>Wir von VENmedia haben für jedes Budget die richtige Lösung.<br/>Unsere drei Rundum-sorglos-Pakete sind dafür bereits ein gutes Indiz. Darüber hinaus erstellen wir Ihnen gerne ein individuelles Angebot.<br/>Füllen Sie dazu einfach unseren Konfigurator mit Ihren Ideen.</center>
                        </Slide>
                        <br/>

                        <div className="grid" style={{padding: 10}}>
                            <div className={"lg:col-4 col-12 " + styles.item +" "+ styles.silverBackground}>
                                <div>
                                    {silverContent}
                                    <center>
                                        <div
                                            className={styles.button}
                                            package="SILVER"
                                            style={{padding: '14px 22px'}}
                                            onClick={() => setForm({...form, paket: 'SILVER'})}
                                        >Jetzt Anfragen</div>
                                    </center>
                                    <br/>
                                </div>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.item + " " + styles.goldBackground}>
                                <div>
                                    {goldContent}
                                    <center>
                                        <div
                                            className={styles.button}
                                            package="GOLD"
                                            style={{padding: '14px 22px'}}
                                            onClick={() => setForm({...form, paket: 'GOLD'})}>Jetzt Anfragen</div>
                                    </center>
                                    <br/>
                                </div>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.item + " " + styles.premiumBackground}>
                                <div>
                                    {premiumContent}
                                    <center>
                                        <div
                                            className={styles.button}
                                            package="PREMIUM"
                                            style={{padding: '14px 22px'}}
                                            onClick={() => setForm({...form, paket: 'PREMIUM'})}>Jetzt Anfragen</div>
                                    </center>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
export default Form