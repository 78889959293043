import styles from './Form.module.css';
import agb1 from './agb1.png'
import agb2 from './agb2.png'
import agb3 from './agb3.png'
import agb4 from './agb4.png'
import {Bounce} from "react-awesome-reveal";
import React from "react";
function Form(props) {
    return (
        <div className="wrapper">
            <div ref={props.childRef}>
                <div style={{background: 'rgba(255,255,255,1)', padding: '15px 30px 15px 30px', borderRadius: '30px'}}>


                    <img src={agb1} style={{width: '100%'}}/>
                    <img src={agb2} style={{width: '100%'}}/>
                    <img src={agb3} style={{width: '100%'}}/>
                    <img src={agb4} style={{width: '100%'}}/>


                    <center>

                        <Bounce delay={700}>
                            <div
                                style={{padding: '10px 20px 10px 20px'}}
                                className="venmediaButton"
                                onClick={() => window.open("/AGB.pdf", '_blank')}>
                                Herunterladen
                            </div>
                        </Bounce>
                    </center>
                </div>
            </div>
        </div>
    )
}

export default Form