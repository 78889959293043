import styles from '../Form.module.css';
import React from "react";
import img1 from "./img1.png";
import img2 from "./img2.png";
import KontaktForm from "../../kontakt/Form";
import {Bounce, Slide, Zoom} from "react-awesome-reveal";
function Form(props) {
    return (
        <div>

            <div className={styles.top}>
                <div className="wrapper">
                    <div className="grid">
                        <div className="col-4">
                            <div style={{textAlign: 'center', width: '100%'}}>
                                <img src={img1} style={{width: '100%'}}/>
                            </div>
                        </div>
                        <div className="col-8">
                            <div>
                                <span className={styles.label}>Softwareerklärung</span>
                                <br/>
                                <br/>
                                <div>
                                    Die Komplexität einer Software zu vermitteln, kann oft eine Herausforderung sein, wenn man sich nur auf Worte verlässt. Daher setzen wir bei Venmedia auf die Kraft der visuellen Kommunikation und ermöglichen Ihnen, Ihre Software in einem animierten Erklärvideo anschaulich und verständlich zu präsentieren.
                                    <br/>
                                    <br/>
                                    Unsere Erklärvideos zeichnen sich durch einen besonderen Aha-Effekt aus, der den Zuschauer fesselt und das Verständnis der Software auf ein neues Level hebt. Durch die dynamische Darstellung im Videoformat wird Ihre Botschaft klar und einprägsam vermittelt.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="wrapper">
                    <div className={styles.items}>
                        <div className="grid">
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <Bounce triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '120px', maxWidth: '100%'}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={styles.itemContent}>
                                                Die Möglichkeiten von Erklärvideos sind nahezu grenzenlos. Wir nutzen diesen Raum, um auch komplexe Themen auf eine leicht verständliche Art und Weise zu erklären. So wird Ihre Software für jedermann zugänglich und ansprechend dargestellt.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <Bounce delay="50" triggerOnce>
                                    <div className="p-grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '140px', maxWidth: '100%'}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={styles.itemContent}>
                                                Unser Fachteam setzt sich mit Leidenschaft und Expertise dafür ein, Ihre individuelle Software umfassend und kreativ zu animieren. Dabei verzichten wir bewusst auf statische Screenshots und bieten Ihnen somit die Freiheit, Ihr Video in einem fließenden und ansprechenden Stil zu präsentieren. Selbstverständlich achten wir darauf, Ihr Logo und Ihre Corporate Identity gemäß Ihren Vorgaben in das Video einzubinden.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <Bounce delay="100" triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '120px'}}><img src={img2} style={{width: '160px', maxWidth: '100%'}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={styles.itemContent}>
                                                Egal ob es um Tutorials für Ihre Nutzer geht oder darum, neue Kunden zu gewinnen – die Wirkung unserer animierten Erklärvideos wird Sie begeistern. Vertrauen Sie auf Venmedia und lassen Sie uns gemeinsam Ihre Software in Szene setzen.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">

                <div  style={{textAlign: 'center'}}>

                    <div className="venmediaHeader">Einfach Und Verständlich Im Videoformat!</div>
                    <div>Überzeugen Sie sich selbst von der kraftvollen Wirkung unserer Erklärvideos und nehmen Sie Kontakt mit uns auf, um Ihre individuelle Lösung zu besprechen.</div>
                    <div>Wir freuen uns darauf, Ihre Software zum Leben zu erwecken!</div>

                </div>
            </div>

            <KontaktForm/>
        </div>
    )
}
export default Form