import {_DELETE, _DOWNLOAD, _POST, _GET} from "./application-react-lib/Api"

export const AppApi = {

    pub: {
        form: {
            url: '/api/pub/contacts/leads',
            post: (content) => _POST(AppApi.pub.form.url, JSON.stringify(content))
        }
    },
}