import styles from './Form.module.css';
import tischImg from "./Tisch.png";
import mausImg1 from "./Maus.png";
import monitorImg1 from "./Monitor.png";
import pflanzeImg1 from "./Pflanze.png";
import tastaturImg1 from "./Tastatur.png";
import touchpadImg from "./Touchpad.png";

import googleImg from "./google.png";
import {Bounce, Slide, Zoom} from "react-awesome-reveal";
import logo1 from "../area6/logo1.png";
import logo2 from "../area6/logo2.png";
import logo3 from "../area6/logo3.png";
import logo4 from "../area6/logo4.png";
import logo5 from "../area6/logo5.png";
import logo6 from "../area6/logo6.png";
import logo7 from "../area6/logo7.png";
import logo8 from "../area6/logo8.png";
import logo9 from "../area6/logo9.png";
import React, {useEffect} from "react";

function Form(props) {
    const video = React.createRef()
    useEffect(() => { video.current.play(); },[]);

    return (
        <div>
            <div className="wrapper">
                <div ref={props.childRef}>


                    <Slide triggerOnce>
                        <div>
                            <div className="venmediaHeader" style={{textAlign: 'center'}}>Mit unseren Erklärvideos erreichen Sie Ihre Ziele!</div>
                            <div style={{textAlign: 'center', fontSize: 24}}>Wir erklären Ihr Angebot in 90 Sekunden!</div>
                        </div>

                    </Slide>

                    <center>
                        <div style={{maxWidth: 900}}>
                            <Slide triggerOnce direction="right">
                                <div style={{position: 'absolute'}}>
                                    <img src={tischImg} style={{width: '100%'}}/>
                                </div>
                            </Slide>

                            <Zoom triggerOnce delay="20">
                                <div style={{position: 'absolute'}}>
                                    <img src={monitorImg1} style={{width: '100%'}}/>
                                </div>
                            </Zoom>

                            <Zoom triggerOnce delay="40">
                                <div style={{position: 'absolute'}}>
                                    <img src={mausImg1} style={{width: '100%'}}/>
                                </div>
                            </Zoom>
                            <Zoom triggerOnce delay="50">
                                <div style={{position: 'absolute'}}>
                                    <img src={pflanzeImg1} style={{width: '100%'}}/>
                                </div>
                            </Zoom>
                            <Zoom triggerOnce delay="60">
                                <div style={{position: 'absolute'}}>
                                    <img src={tastaturImg1} style={{width: '100%'}}/>
                                </div>
                            </Zoom>
                            <Zoom triggerOnce delay="70">
                                <div style={{position: 'absolute'}}>
                                    <img src={touchpadImg} style={{width: '100%'}}/>
                                </div>
                            </Zoom>

                            <Zoom triggerOnce delay="30">
                                <div style={{position: 'absolute', margin: '8% 5% 5% 1%', height: '1000px', width: '100%'}}>
                                    <div style={{verticalAlign: 'center'}}>
                                        <video
                                            onMouseOver={() => {
                                                //video.current.muted = false;
                                                //video.current.play();
                                            }}
                                            controls
                                            muted
                                            autoPlay={"autoplay"}
                                            preload="auto"
                                            loop
                                            ref={video}
                                            width="48%"
                                            id="video">
                                            <source src="/video.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </Zoom>
                            <Slide triggerOnce delay="20" direction="right">
                                <div style={{float: 'right', textAlign: 'right', width: '23%'}}>
                                    <div style={{paddingTop: '26%'}}></div>
                                    <a
                                        href="https://www.provenexpert.com/venmedia/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                                        title="Kundenbewertungen &amp; Erfahrungen zu VENmedia. Mehr Infos anzeigen."
                                        target="_blank"
                                        style={{textDecoration: 'none'}}
                                        rel="noopener noreferrer"><img src="https://images.provenexpert.com/9b/62/ccb6c977c77e11cfecc95209969b/widget_portrait_250_de_0.png" alt="Erfahrungen &amp; Bewertungen zu VENmedia" style={{border: 0, width: 'clamp(80px, 60%, 130px)'}} /></a>
                                    <div style={{paddingTop: '2%'}}></div>
                                    <a
                                        href="https://www.google.de/search?q=venmedia&source=hp&ei=jiSCYteEFd6Rxc8P5fq5qAU&iflsig=AJiK0e8AAAAAYoIyns2FFWL1L9pY2tWw2k_wDkBu8J7y&ved=0ahUKEwjXg7SS5eP3AhXeSPEDHWV9DlUQ4dUDCAk&uact=5&oq=venmedia&gs_lcp=Cgdnd3Mtd2l6EAMyCwguEIAEEMcBEK8BMgYIABAKEB4yCAgAEAUQChAeMgQIABAeMgYIABAPEB4yAggmOggILhCxAxCDAToICAAQsQMQgwE6CwgAEIAEELEDEIMBOhEILhCABBCxAxCDARDHARDRAzoOCC4QgAQQsQMQxwEQ0QM6CAgAEIAEELEDOgsILhCABBDHARDRAzoFCAAQgAQ6CwguEIAEELEDEIMBOgsILhCABBCxAxDUAjoLCC4QgAQQxwEQowI6CAguEIAEELEDOgcIABCABBAKOg0ILhCABBDHARDRAxAKOgcILhCxAxAKOgoIABCxAxCDARAKOgQIABAKOgcIABCxAxAKUABYsAdgvAhoAHAAeACAAcsBiAGiBpIBBTMuMy4xmAEAoAEB&sclient=gws-wiz#lrd=0x47bd09f5e3d8c357:0x8169061996e6ca33,1,,,"
                                        title="Kundenbewertungen &amp; Erfahrungen zu VENmedia. Mehr Infos anzeigen."
                                        target="_blank"
                                        style={{textDecoration: 'none'}}
                                        rel="noopener noreferrer"><img src={googleImg} style={{width: 'clamp(80px, 60%, 130px)'}}/></a>

                                </div>
                            </Slide>

                            <Slide triggerOnce delay="20" direction="left">
                                <div style={{float: 'left', textAlign: 'left', width: '23%'}}>
                                    <div style={{paddingTop: '26%'}}></div>
                                    <a
                                        href="https://www.provenexpert.com/venmedia/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                                        title="Kundenbewertungen &amp; Erfahrungen zu VENmedia. Mehr Infos anzeigen."
                                        target="_blank"
                                        style={{textDecoration: 'none'}}
                                        rel="noopener noreferrer"><img src="https://images.provenexpert.com/topservice/de-de/topservice_180.png" alt="Erfahrungen &amp; Bewertungen zu VENmedia" style={{border: 0, width: 'clamp(80px, 60%, 130px)'}} /></a>
                                    <div style={{paddingTop: '2%'}}></div>
                                    <a
                                        href="https://www.provenexpert.com/venmedia/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                                        title="Kundenbewertungen &amp; Erfahrungen zu VENmedia. Mehr Infos anzeigen."
                                        target="_blank"
                                        style={{textDecoration: 'none'}}
                                        rel="noopener noreferrer"><img src="https://images.provenexpert.com/toprecommendation/de-de/toprecommendation_180.png" alt="Erfahrungen &amp; Bewertungen zu VENmedia" style={{border: 0, width: 'clamp(80px, 60%, 130px)'}} /></a>

                                </div>
                            </Slide>

                        </div>
                    </center>
                </div>

                <div>
                    <div style={{height: '60vw', maxHeight: 530}}></div>
                    <center>
                        <table>
                            <tbody>
                            <tr>
                                <th><img src={logo1} className={styles.logo} style={{height: 'clamp(12px, 2.5vw, 30px)', paddingRight: 25}}/></th>
                                <th><img src={logo2} className={styles.logo} style={{height: 'clamp(12px, 2.5vw, 30px)', paddingRight: 25}}/></th>
                                <th><img src={logo3} className={styles.logo} style={{height: 'clamp(12px, 2.5vw, 30px)', paddingRight: 25}}/></th>
                                <th><img src={logo4} className={styles.logo} style={{height: 'clamp(12px, 2.5vw, 30px)', paddingRight: 25}}/></th>
                                <th><img src={logo5} className={styles.logo} style={{height: 'clamp(12px, 2.5vw, 30px)', paddingRight: 25}}/></th>
                                <th><img src={logo6} className={styles.logo} style={{height: 'clamp(12px, 2.5vw, 30px)', paddingRight: 25}}/></th>
                                <th><img src={logo7} className={styles.logo} style={{height: 'clamp(12px, 2.5vw, 40px)', paddingRight: 25}}/></th>
                                <th><img src={logo8} className={styles.logo} style={{height: 'clamp(12px, 2.5vw, 30px)', paddingRight: 25}}/></th>
                                <th><img src={logo9} className={styles.logo} style={{height: 'clamp(12px, 2.5vw, 40px)'}}/></th>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                    </center>
                </div>
                <center>
                    <Bounce triggerOnce delay="150">
                        <div className="venmediaButton" onClick={() => props.onAngebotSichernClick()}>
                            Angebot sichern
                        </div>
                    </Bounce>
                </center>
            </div>
        </div>
    )
}
export default Form