import React, {Component} from 'react';
import styles from './Form.module.css';
import logoDark from "./logo_dark.png";
import {Menu} from "primereact/menu";
import {Button} from "primereact/button";


class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.action = {
            selectItem: (item) => {
                this.props.onItemSelect && this.props.onItemSelect(item)
            }
        }
    }


    render() {
        let items = [
            {label: 'Unsere Pakete', command:() => this.action.selectItem('UNSERE_PAKETE')},
            {label: 'Konfigurator', command:() => this.action.selectItem('KONFIGURATOR')},
            {label: 'Referenzen', command:() => this.action.selectItem('REFERENZEN')},
            {label: 'Das sind wir', command:() => this.action.selectItem('DAS_SIND_WIR')},
            {label: 'Kontakt', command:() => this.action.selectItem('KONTAKT')}
        ];

        return (
        <div>
            <div className={styles.root}>
                <div className="wrapper" style={{padding: 0}}>
                    <div className="grid">
                        <div className="col-3">
                            <img src={logoDark} style={{width: 200, cursor: 'pointer'}} onClick={() => this.action.selectItem('START')}/>
                        </div>
                        {items.map( item => item.label != "Kontakt" && (
                            <div className="col-2 hidden lg:inline-flex">
                                <div className={styles.item} onClick={() => item.command()}>
                                    {item.label}
                                </div>
                            </div>
                        ))}

                        <div className="col-1 hidden lg:inline-flex">
                            <div className={styles.item} onClick={() => this.action.selectItem('KONTAKT')}>
                                <div className={styles.contactButton}>
                                    Kontakt
                                </div>
                            </div>
                        </div>
                        <div className="col-8 lg:hidden">
                            <div style={{marginRight: 15, float: 'right'}}>
                                <Menu model={items} popup ref={el => this.menu = el} id="popup_menu"/>
                                <Button

                                    icon="pi pi-bars"
                                    onClick={(event) => this.menu.toggle(event)}
                                    aria-controls="popup_menu"
                                    aria-haspopup />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <br/>
            <br/>
            <br/>
        </div>)
    }
}

export default Form;
