import styles from ".//Form.module.css";
import TeamAnimation from "./animation/Form"
import element1 from "./1avatar.png"
import element2 from "./2avatar.png"
import element3 from "./3avatar.png"
import element4 from "./4avatar.png"
import element5 from "./5avatar.png"
import element6 from "./6avatar.png"
import element7 from "./7avatar.png"
import element8 from "./8avatar.png"
import React from "react";
import image1 from "./5031658.png";
import image2 from "./5492342.png";
import image3 from "./erfahrung.png";
import {Slide} from "react-awesome-reveal";

function Form(props) {

    const ceos = [
        { img: element4, name: 'Anil I.' },
        { img: element5, name: 'Adem T.' }
    ]
    const employes = [
        { img: element1, name: 'Sabrina N.' },
        { img: element2, name: 'Lian S.' },
        { img: element3, name: 'Maria C.' },
        { img: element6, name: 'Jonas S.' },
        { img: element7, name: 'Rascho R.' },
        { img: element8, name: 'Julia W.' }
    ]

    return (
        <React.Fragment>
            <div className="wrapper" style={{padding: 0}}>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Slide triggerOnce delay={400}>
                    <div style={{width: '100%'}}>
                        <center><div className="venmediaHeader">Das VENmedia - Team</div></center>
                    </div>
                </Slide>
                <TeamAnimation/>
            </div>
            <div style={{background: 'var(--venmediaColor2)'}}>
                <div className="wrapper">
                    <div>
                        <div className="venmediaHeader2" style={{color: '#FFFFFF'}}>Über uns</div>
                        <div  style={{color: 'var(--venmediaColor-2)'}}>
                            Seit 4 Jahren begleiten wir Kunden und Kundinnen mit der qualitätsvollen Produktion einzigartiger Erklärvideos, die speziell für den jeweiligen Vertrieb konzipiert sind. Unser dynamisches Team setzt sich aus Fachleuten unterschiedlicher Richtungen zusammen. Es sind ausgebildete Grafiker, Motion Designer, Texter, Sprecher und Projektmanager, Männer wie Frauen, die durch den gekonnten Einsatz von Farben, Bildern und Schlüsselszenen Emotionen wecken. Mit unserem Knowhow und unserer Leidenschaft lenken wir die Aufmerksamkeit der potenziellen Kundschaft auf Ihr Produkt oder auf Ihre Dienstleistung.
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <center>
                    {ceos.map( avatar => (

                        <div  style={{padding: 10, display: 'inline-block', width: '44%'}}>
                            <div className={styles.imgBorder}>
                                <img src={avatar.img} style={{width: '100%'}}/>
                            </div>
                            <div>
                                <center>{avatar.name}</center>
                            </div>
                        </div>
                    ))}
                    {employes.map( avatar => (

                        <div  style={{padding: 10, display: 'inline-block', width: '15%'}}>
                            <div className={styles.imgBorder}>
                                <img src={avatar.img} style={{width: '100%'}}/>
                            </div>
                            <div>
                                <center>{avatar.name}</center>
                            </div>
                        </div>
                    ))}
                </center>


                <div className={styles.bottomItem}>
                    <div className="grid">
                        <div className="col-3">
                            <img src={image1} style={{width: '100%'}}/>
                        </div>
                        <div className="col-9">
                            <div className="venmediaHeader3">Individuelle Erklärvideos aus Ihren Ideen</div>
                            <div>
                                Ihre Ideen sind unser Antrieb. Änderungswünsche sind bei uns kostenfrei. Denn wir wollen, dass das Video hundertprozentig den Wünschen unserer Kunden und Kundinnen entspricht. Mit großer Freude stellen wir uns täglich dieser Herausforderung und sind erst dann zufrieden mit einem Projekt, wenn Sie es auch sind. Fantasie und kreatives Geschick gehören ebenso zu unserer Arbeit wie der Blick über den Tellerrand hinaus. Wir stellen uns auf jeden Auftraggeber und jedes Projekt individuell ein und finden so das passende Konzept, um auch komplexe Inhalte verständlich und unterhaltsam zu vermitteln. Egal, ob Legetechnik, Cartoon oder Flat - unsere Erklärvideos überzeugen mit sympathischen Charakteren, starkem Storytelling und professioneller Umsetzung.
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.bottomItem}>
                    <div className="grid">
                        <div className="col-9">
                            <div className="venmediaHeader3">Ihr Erfolg ist unsere Anerkennung</div>
                            <div>
                                Unsere Mission ist es, Ihre Produkte und Dienstleistungen so ansprechend in ein Video zu verpacken, dass sie gerne gekauft werden. Wenn es uns mit einem Erklärvideo gelingt, Ihr Produkt ideal zu platzieren, haben wir unser Ziel erreicht. Das ist der Turbo für die Entwicklung und Umsetzung immer wieder neuer einzigartiger Ideen an unseren Zeichentischen und Bildschirmen.
                            </div>
                        </div>
                        <div className="col-3">
                            <img src={image3} style={{width: '100%'}}/>
                        </div>
                    </div>
                </div>

                <div className={styles.bottomItem}>
                    <div className="grid">
                        <div className="col-3">
                            <img src={image2} style={{width: '100%'}}/>
                        </div>
                        <div className="col-9">
                            <div className="venmediaHeader3">VENmedia - Kompetenz trifft Einfühlungsvermögen</div>
                            <div>
                                Mit VENmedia entscheiden Sie sich für einen professionellen Partner im Bereich der Erklärvideo-Produktion. Wir verstehen uns nicht als Dienstleister, sondern als Partner auf Augenhöhe. Ein Kreativteam, das daran arbeitet, die Wahrnehmung Ihres Unternehmens im digitalen Netz zu verbessern.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}
export default Form